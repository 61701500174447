import { CSSProperties, useRef, useState } from 'react';

import LinkedInLogo from './linkedin-logo.svg';
import GitHubLogo from './github-logo.svg';
import MastodonLogo from './mastodon-logo.svg';
import './App.scss';

const Apps = [
  {
    img: 'http://www.qqfalta.com.br/images/logo_qqfalta.png',
    url: 'http://www.qqfalta.com.br/'
  },
  {
    img: 'vendas-icon.png',
    appId: 'br.com.uol.ps'
  },
  {
    img: 'smart-pos.webp',
    url: 'https://pagseguro.uol.com.br/para-seu-negocio/maquininhas/moderninha-smart-2'
  },
  {
    img: 'pagbank-icon.png',
    appId: 'br.com.uol.ps.myaccount'
  },
  {
    img: 'clip-icon.png',
    appId: 'com.payclip.clip'
  },
  {
    img: 'dosh-icon.png',
    appId: 'com.dosh.client'
  },


]

const PlayStoreUrl = `https://play.google.com/store/apps/details?id=`

function App() {
  let screenHeight = window.screen.availHeight

  let [isDockedClassName, setIsDockedClassName] = useState('')
  

  function a(e: any) {
    let scroll = e.target.scrollTop
    let hasScrolledEnough = scroll > (1 / 3 * screenHeight)
    if (hasScrolledEnough) {
      setIsDockedClassName('isDocked')
    } else {
      setIsDockedClassName('')
    }
    
  }

  return (
    <div className="App" onScroll={a}>
      <div className={`Header ${isDockedClassName}`}>
        <div id='main'>
          <img src="logo.svg" className="App-logo" alt="logo" />
          <div className='Side'>
            Developing solutions and people
          </div>
          <div className='Contact'>
            <a href="https://www.linkedin.com/in/rodrigoamarorodrigues/" target="_new"><img src={LinkedInLogo} /></a>
            <a href="https://github.com/RodrigoAmaroEng" target="_new"><img src={GitHubLogo} /></a>
            <a href="https://mastodon.social/@rodrigoamaro@mastodon.social" target="_new"><img src={MastodonLogo} /></a>
            <a href="https://stackoverflow.com/users/2615142/amaro" target="_new"></a>
          </div>
        </div>
      </div>
      <div id='face' className={isDockedClassName}></div>
      <div className='Main'>
        <div className='Section'>
          <article>
            <h1>Hey, I'm Rodrigo Amaro, a Brazilian systems architect passionate about delivering tech solutions that make everyone's lives better</h1>
          </article>
        </div>
        <div className='Section'>
          <article>
            <h2>Portfolio</h2>
            <div className='Portfolio'>
              {Apps.map((it: any) => (
                <a target="_new" href={it.url ?? PlayStoreUrl + it.appId}><img src={it.img} /></a>
              ))}
            </div>
          </article>
        </div>
        <div className='Section'>
          <article>
            <h2>Personal projects</h2>
            <div className='Project'>
              <a href="https://amaro-dev.github.io/android-compare/" target="new"><h5>Screenwise</h5></a>
              <div>A tool to compare different Android screen sizes and visualize how the content will differ in each one</div>
            </div>
            <div className='Project'>
              <a href="https://github.com/amaro-dev/sonic" target="new"><h5>Sonic</h5></a>
              <div>A small wrapper to implement MVI multiplatform projects using Kotlin</div>
            </div>
          </article>
        </div>
        <div className='Section'>
          <article>
            <h2>Experience</h2>
            <div className='Timeline'>
              <div className='Company' aria-label='Jan/22'>
                <h5>Cardlytics 🇺🇸</h5>
                <label>Team lead / Mobile developer</label>
                <div className='About'>
                  Through partnerships with top banks, Cardlytics have a complete view of consumer spend, including purchases they make at competitors. This purchase intelligence is the foundation of all their offered services.
                </div>
                <div className='Accomplishments'>
                  <ul>
                    <li>Maintainament and enhancement of their Android library</li>
                    <li>Coordination and support for the App team</li>
                    <li>Process and solutions proposals to improve the development cycle</li>
                    <li>Managed/Mentored other developers in the company</li>
                  </ul>
                </div>
                <ul className='TechStack'>
                  <li>Android</li>
                  <li>Java</li>
                  <li>Kotlin</li>
                  <li>GraphQL</li>
                  <li>Node.JS</li>
                  <li>React.JS</li>
                </ul>
              </div>
              <div className='Company' aria-label='Apr/20'>
                <h5>Clip 🇲🇽</h5>
                <label>Engineer Manager</label>
                <div className='About'>
                  Providing both mobile and in-site payment solution, Clip is one of the biggest fintechs in Mexico.
                </div>

                <div className='Accomplishments'>
                  <ul>
                    <li>Security assessment for all payment devices of the company</li>
                    <li>Revision of the development cycle and reorganization of the teams</li>
                    <li>Improvements on the CI/CD process to comply with the PCI requirements</li>
                    <li>Implementation of an APM system for all mobile apps</li>
                    <li>Coordination with many hardware providers</li>
                    <li>Complete revamp of their SDK</li>
                  </ul>
                </div>
                <ul className='TechStack'>
                  <li>Android</li>
                  <li>Java</li>
                  <li>Kotlin</li>
                  <li>GitHub/Actions</li>
                </ul>
              </div>
              <div className='Company' aria-label='Jan/12'>
                <h5>PagSeguro 🇧🇷</h5>
                <label>Senior/Lead developer - Mobile</label>
                <div className='About'>
                  PagSeguro is a fintech company based in Sao Paulo - Brazil that provides payment solutions for their clients. Back this February it opened capital on the NYSE with a huge impact.
                </div>
                <div className='Accomplishments'>
                  <ul>
                    <li>Deployed the first mobile payment system in Brazil built from the scratch</li>
                    <li>Project and implementation for the integration with multiple card reader devices. Bluetooth / Audio Jack port</li>
                    <li>Responsible for the design and implementation of the CI/CD process</li>
                    <li>Creation of 2 SDKs for providing mobile payment services to other applications</li>
                    <li>Lead developer on of the first Smart PoS devices in Brazil (Android embeeded) </li>
                    <li>Lead developer for the PagBank (Banking App) expansion coordinating the work of more than 30 teams</li>
                  </ul>
                </div>
                <ul className='TechStack'>
                  <li>Android</li>
                  <li>Java</li>
                  <li>Kotlin</li>
                  <li>Jenkins</li>
                  <li>Oracle</li>
                  <li>REST</li>
                  <li>Bitbucket/Stash</li>
                  <li>BitRise</li>
                  <li>BrowserStack</li>
                </ul>
              </div>
              <div className='Company' aria-label='Sep/10'>
                <h5>Petrobras 🇧🇷</h5>
                <label>Senior developer - Fullstack </label>
                <div className='About'>Petrobras is an oil company currently controlled by the Brazilian government with operations all around the globe.</div>
                <div className='Accomplishments'>
                  <ul>
                    <li>Maintainament of several legacy Asp 3.0 systems</li>
                    <li>Project and implementation of a new inventory fuel control system in refineries, written in Asp.Net C# 3.5</li>
                    <li>Implemented an Access Control system to improve security in refineries. C# for Windows CE embeeded portable devices</li>
                    <li>Project for a fuel sample management system using RFID. C# for Windows CE embeeded portable devices</li>
                    <li>Part of a study group for mobile applications</li>
                  </ul>
                </div>
                <ul className='TechStack'>
                  <li>Asp 3.0</li>
                  <li>Asp.Net</li>
                  <li>C#</li>
                  <li>HTML</li>
                  <li>JavaScript</li>
                  <li>C# for Mobile (Windows CE)</li>
                  <li>Android</li>
                  <li>Java</li>
                  <li>Microsoft SQL Server</li>
                  <li>Oracle</li>
                  <li>ClearCase</li>
                </ul>
              </div>
              <div className='Company' aria-label='Mar/06'>
                <h5>Foster 🇧🇷</h5>
                <label>Developer - Fullstack</label>
                <div className='About'>Foster is a marketing agency and software house, part of the WPP group</div>
                <div className='Accomplishments'>
                  <ul>
                    <li>Developed several promotional websites for famous Brazilian brands using Asp 3.0</li>
                    <li>Built new features for the company's white label CMS solution, written in Asp.Net C# 3.5</li>
                    <li>Deployed this CMS with both layout and feature customizations on several clients</li>
                  </ul>
                </div>
                <ul className='TechStack'>
                  <li>Asp 3.0</li>
                  <li>Asp.Net</li>
                  <li>C#</li>
                  <li>Visual Basic (.Net)</li>
                  <li>HTML</li>
                  <li>JavaScript</li>
                  <li>XSLT</li>
                  <li>Microsoft SQL Server</li>
                  <li>VCS/VSS</li>
                  <li>SVN</li>
                </ul>
              </div>
              <div className='TheLine'></div>
            </div>
            <div style={{ marginBottom: '5vh' }}></div>
          </article>
        </div>
        <div className='Section'>
          <article>
            <h2>Background</h2>
            <div className='Timeline'>
              <div className='Background' aria-label='Nov/10'>
                <h5>Postgraduate degree in Systems Analysis</h5>
                <label>Universidade Presbiteriana Mackenzie</label>
              </div>
              <div className='Background' aria-label='Jan/07'>
                <h5>Microsoft Certification</h5>
                <label>Asp.NET web developer</label>
              </div>
              <div className='Background' aria-label='Nov/05'>
                <h5>Bachelor Computer Engineer degree</h5>
                <label>Universidade Santa Cecília</label>
              </div>
              <div className='TheLine'></div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default App;
